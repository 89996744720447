import React from 'react';
import { FreeMode } from 'swiper';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { container, slider, navItem, accented } from './user-mobile-navigation.module.scss';
import Logout from '../../assets/images/svg/logout.svg';
import { INavLink } from '../../models/nav-link.model';
import useTranslations from '../../hooks/use-translations';

import Slider from '../hoc/slider';
import UserNavigationItem from '../atoms/user-navigation-item';

export interface IUserMobileNavigationProps {
    className?: string;
    links: INavLink[];
    onLogout?: () => void;
}

export default function UserMobileNavigation({
    className = '',
    links,
    onLogout,
}: IUserMobileNavigationProps) {
    const t = useTranslations('UserNavigation');
    const { pathname, baseUrl } = usePageContext();
    const initialIndex = links.findIndex((link) => link.value === `${baseUrl}${pathname}`);

    return (
        <div className={`${className} ${container}`}>
            <Slider
                className={slider}
                slidesPerView="auto"
                freeMode
                modules={[FreeMode]}
                initialSlide={initialIndex}
            >
                {links.map((link) => (
                    <UserNavigationItem navLink={link} className={navItem} />
                ))}
                <UserNavigationItem className={[navItem, accented].join(' ')} onClick={onLogout}>
                    {t.logout} <Logout />
                </UserNavigationItem>
            </Slider>
        </div>
    );
}
